// basic
$c-main: #805a35;
$c-main-brown: #805A35;
$c-main-yellow: #F9DB4A;
$c-main-pink: #F5BDBD;
$c-main-light-pink: #FEEEEA;
$c-white: #FFF;
$c-grey: #707070;
$c-grey2: #E6D0C6;
$c-black: #000;
$c-tab-bg: #F9F5EF;
$c-table-row-stripe: #FFC3B4;
$c-assist-pink: #FF9FA6;
$c-assist-purple: #D3A9E0;
$c-assist-purple2: #EDD0EA;
$c-assist-purple3: #ECD0E9;
$c-assist-purple4: #FAF1F8;
$c-assist-green: #7FD3B6;
$c-assist-green2: #A7EDCF;
$c-assist-green3: #EDFBF3;
$c-assist-blue: #83D0E6;
$c-assist-blue2: #F1FCFB;
$c-assist-light-brown: #B8A26F;
$c-home-light-blue: #EFFBFF;
$c-red: #FF000A;
$c-declare-bg: #F8A7A7;
$c-other-pink: #EE8A8E;
$c-error-404: #F5BDBD80;

$c-north-bg: #F1FCFB;
$c-middle-bg: #FFF7F8;
$c-south-bg: #F8F6FE;
$c-east-bg: #FFF8EC;

$c-main-light: rgba($c-main, 0.4);

$c-assist: #696969;
$c-assist2: #7A5B3B;

// pure
$c-white: #fff;
$c-black: #000;

$c-white2: #f7f7f7;
$c-white3: #f9f9f9;
$c-white4: #fffffd;
$c-black2: #242424;

$c-text1: #a2a2a2;
$c-text2: #cecdcd;
$c-text3: #535353;

$c-dice--pink: #FA8A92;
$c-dice--purple: $c-assist-purple;

// $c-assist1: #f4f4f4;
// $c-assist2: #ececec;
// $c-assist3: #a0a0a0;
// $c-assist4: #808080;
// $c-assist5: #efefef;
// $c-assist6: #484848;
// $c-assist7: #e0dfdf;
// $c-assist8: #313131;
// $c-assist9: #727272;
// $c-assist10: #d9d9d9;
// $c-assist11: #a6a6a6;
// $c-assist12: #191919;
// $c-assist13: #4d4d4d;
// $c-assist14: #5f5e5e;
// $c-assist15: #c7c7c7;


$c-input-placeholder: #5f5e5e;
$c-home-search-bg: #ffffffd9;

// error
$c-error: #cd5c5c;
