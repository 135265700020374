// * {
//   outline: 1px dashed $c-red;
// } 

html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 16px !important; // set for rem base
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#app {
  width: 100%;
  height: 100%;
  max-width: 2560px;
  margin: 0 auto;
  overflow-x: hidden;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
