@import "./src/styles/index.scss";

@import '~@/styles/utils/_rwd.scss';
.c-sheet {
  @include max-width(1100);
  width: 100%;
  margin: 0 auto;

}

