@import "./src/styles/index.scss";

@import '~@/styles/utils/_rwd.scss';

.g-content {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: $mobile) {
  .g-content {
    // padding: 0px;
  }
}
