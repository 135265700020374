@import "./src/styles/index.scss";

.c-accordion {
  background-color: $c-main-light-pink;
  border-radius: 20px;

  &--mt {
    margin-top: 110px;
  }

  & + & {
    margin-top: 20px;
  }

  &__content {
    @include padding(24px 20px);
  }
}

.title {
  @include flex(space-between);
  cursor: pointer;

  &__text {
    @include font-style($c-main, 20, 700, 1.6px, 28px);
  }

  &__expand {
    flex: none;
    width: 40px;
    height: 40px;
    position: relative;
    background-color: $c-main-yellow;
    border-radius: 20px;
    @include flex(center);
    margin-left: 15px;

    &__icon {
      transition: 0.3s;
      transform: rotate(0deg);

      &--active {
        transform: rotate(180deg);
      }
    }
  }
}

.desc {
  border-top: 1px solid rgba($c-main, 0.5);
  @include padding(24px 0 0 0);
  margin-top: 20px;

  &__text {
    @include font-style($c-main, $f-normal-text, 400, 4px);
  }
}

.text-loop {
  text-align: justify;
  @include font-normal();
}

.vhtml-content {
  @include font-style($c-main, $f-normal-text, 400, 4px);

}

@media screen and (min-width: $tablet) {
  .c-accordion {
    &__content {
      @include padding(24px 44px);
    }
  }
}
