@import "./src/styles/index.scss";

@import '~@/styles/utils/_rwd.scss';
.c-section-title {
  position: relative;

  &--deco {
    &::after {
      content: '';
      @include position(bl, -20px, 0);
      display: inline-block;
      background-color: $c-main-yellow;
      width: 100px;
      height: 5px;
    }
    margin-bottom: 0px;
  }

  &--mt {
    margin-top: 100px;
  }

  &--mb {
    margin-bottom: 70px;
  }
}
.title {
  @include font-style($c-main, 24, 500, 2.6px, 32px);
}

@media screen and (min-width: $tablet) {
  // .title {
  //   @include font-style($c-main, 25, 500, 1.4px);
  // }
}

@media screen and (min-width: $pc) {
  .title {
    @include font-style($c-main, 28, 500, 1.4px);
  }
}
