@import "./src/styles/index.scss";


.c-loading {
  @include flex(center);
}
.loader {
  // margin: 0 0 2em;
  // width: 10px;
  // height: 10px;
  // width: 20%;
  text-align: center;
  // padding: 1em;
  // margin: 0 auto 1em;
  display: inline-block;
  // vertical-align: top;
}

svg path,
svg rect {
  fill: $c-table-row-stripe;
}
