// 字
// 參數 顏色, 字體大小, 字體粗細, 字距
@mixin font-style($c: #000, $fs: 16, $fw: 400, $ls: normal, $lh: normal) {
  color: $c;
  font-size: calc($fs / 16) + rem;
  font-weight: $fw;
  letter-spacing: $ls;
  line-height: $lh;
}

// 內頁使用
@mixin max-width($w, $isCenter: true) {
  @include padding(0 20px);
  max-width: 100%;

  @media screen and (min-width: $mobile) {
    @include padding(0px);
    max-width: 90%;
  }

  @media screen and (min-width: $sm-pc) {
    max-width: $w + px;
  }

  @if $isCenter == true {
    margin: 0 auto;
  }
}

@mixin font-size($fs: 16) {
  font-size: calc($fs / 16) + rem;
}

@mixin padding($padding) {
  box-sizing: border-box;
  padding: $padding;
}

@mixin flex($js: flex-start, $ai: center, $fd: row) {
  display: flex;
  justify-content: $js;
  align-items: $ai;
  flex-direction: $fd;
}

@mixin inline-flex($js: flex-start, $ai: center, $fd: row) {
  display: inline-flex;
  justify-content: $js;
  align-items: $ai;
  flex-direction: $fd;
}

@mixin position($type: center, $tb: 0px, $lr: 0px ) {
  @if $type == center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @if $type == tl {
    position: absolute;
    top: $tb;
    left: $lr;
  }

  @if $type == tr {
    position: absolute;
    top: $tb;
    right: $lr;
  }

  @if $type == bl {
    position: absolute;
    bottom: $tb;
    left: $lr;
  }

  @if $type == br {
    position: absolute;
    bottom: $tb;
    right: $lr;
  }
}

@mixin cus-radius($tl, $tr, $br, $bl) {
  border-top-left-radius: $tl;
  border-top-right-radius: $tr;
  border-bottom-right-radius: $br;
  border-bottom-left-radius: $bl;
}

// 移除button 所有預設
@mixin set-btn-default() {
  border: 0px transparent;
  outline: 0px transparent;
  background-color: transparent;
  cursor: pointer;
}

@mixin circle($wh: 10px, $r: 50%) {
  width: $wh;
  height: $wh;
  border-radius: $r;
  
}

@mixin font-normal($c: $c-main, $fs: $f-normal-text, $fw: 400, $ls: 1.6px, $lh: 28px) {
  @include font-style($c, $fs, $fw, $ls, $lh);
}

@mixin font-important($c: $c-main, $fs: 20, $fw: 500, $ls: 2px, $lh: 28px) {
  @include font-style($c, $fs, $fw, $ls, $lh);
}


@mixin h100() {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

// 行數省略
@mixin word-ellipsis($row: 1) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
