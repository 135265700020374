@import "./src/styles/index.scss";

.c-paragraph {
  width: 100%;
  .text {
    &:first-child {
      margin-top: 0;
    }
  }
}

.text {
  text-align: justify;
  text-align-last: left;
  word-break: break-all;

  &--large {
    @include font-style($c-main, 35, 700, 3px, 51px);
    margin-top: 20px;
  }

  &--normal {
    @include font-normal();
    margin-top: 10px;
  }

  &--normal-indent {
    @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
    padding-left: 16px;
  }

  &--normal-bold {
    @include font-style($c-main, $f-normal-text, 700, 1.6px, 28px);
    margin-top: 20px;
  }

  &--normal-underline {
    @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
    text-decoration: underline;
    margin-top: 20px;
  }

  &--important {
    @include font-important();
    margin-top: 40px;
  }

  &--important-bold {
    @include font-important();
    font-weight: 700;
    margin-top: 40px;
  }

  &--important-center {
    @include font-important();
    text-align: center;
    margin-top: 20px;
  }
}

.text-loop {
  text-align: justify;
  text-align-last: left;
  word-break: break-all;

  &__word {
    display: block;
    text-align: justify;
    text-align-last: left;

    &:first-child {
      margin-top: 20px;
    }

    & + & {
      margin-top: 0;
    }

    &--large {
      @include font-style($c-main, 35, 700, 3px, 51px);
    }

    &--normal {
      @include font-normal();
      margin-top: 10px;

      &:first-child {
        margin-top: 10px;
      }
    }

    &--normal-indent {
      @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
      padding-left: 16px;
    }

    &--normal-bold {
      @include font-style($c-main, $f-normal-text, 700, 1.6px, 28px);
      margin-top: 20px;
    }

    &--normal-underline {
      @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
      text-decoration: underline;
    }

    &--important {
      @include font-important();
      margin-top: 40px;
    }

    &--important-bold {
    @include font-important();
    font-weight: 700;
    margin-top: 40px;
  }

    &--important-center {
      @include font-important();
      text-align: center;
      margin-top: 20px;
    }
  }
}
